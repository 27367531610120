import Footer from 'src/components/Footer';
import Header from 'src/components/Header';

type DefaultLayoutProps = {
  children: React.ReactNode;
  pageProps: {
    pageUrl: string;
  };
};

const DefaultLayout = ({ children, pageProps }: DefaultLayoutProps) => (
  <>
    <Header />
    <main>{children}</main>
    <Footer pageUrl={pageProps.pageUrl} />
  </>
);

export default DefaultLayout;
