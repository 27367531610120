import 'src/global.css';
import '@lightspeed/golf-b2c-design-system/theme.css';
import 'src/theme-overrides.css';
import 'src/react-datepicker.css';
import '@lightspeed/golf-b2c-design-system/Button/button.css';
import '@lightspeed/golf-b2c-design-system/ButtonIcon/buttonIcon.css';
import '@lightspeed/golf-b2c-design-system/Checkbox/checkbox.css';
import '@lightspeed/golf-b2c-design-system/Modal/modal.css';
import '@lightspeed/golf-b2c-design-system/RadioGroup/radioGroup.css';
import '@lightspeed/golf-b2c-design-system/Select/select.css';
import '@lightspeed/golf-b2c-design-system/Spinner/spinner.css';

import { ReactElement, ReactNode } from 'react';

import * as Tooltip from '@radix-ui/react-tooltip';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import axios from 'axios';
import { camelCase, snakeCase } from 'lodash-es';
import { NextPage } from 'next';
import type { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useRouter } from 'next/router';

import ErrorBoundary from 'src/components/ErrorBoundary';
import { SessionWrapper } from 'src/contexts/session';
import DefaultLayout from 'src/layouts/default';
import { transformObjectKeys } from 'src/utils/casing';
import { useTxWithCache } from 'src/utils/i18n';
import { extractPagination } from 'src/utils/pagination';

const ZenDeskWidget = dynamic(
  () => import('src/components/ZendDeskWidget').then((m) => m.ZenDeskWidget),
  { ssr: false }
);

const GoogleTagManager = dynamic(
  () => import('src/components/GoogleTagManager').then((m) => m.GoogleTagManager),
  { ssr: false }
);

const DataDogInitializer = dynamic(
  () => import('src/components/DataDogInitializer').then((m) => m.DataDogInitializer),
  { ssr: false }
);

const GoogleBookingInitializer = dynamic(
  () => import('src/components/GoogleBookingInitializer').then((m) => m.GoogleBookingInitializer),
  { ssr: false }
);

axios.defaults.headers.common.Accept = 'application/json';
if (typeof window === 'undefined') {
  axios.defaults.headers.common['Accept-Encoding'] = 'identity';
}
axios.defaults.withCredentials = true;
axios.interceptors.request.use(
  (request) => ({
    ...request,
    data: transformObjectKeys(snakeCase, request.data),
  }),
  null,
  { synchronous: true }
);
axios.interceptors.response.use(
  (response) => ({
    ...response,
    data: transformObjectKeys(camelCase, response.data),
  }),
  null
);
axios.interceptors.response.use(extractPagination, null);

const queryClient = new QueryClient();

export type NextPageWithLayout<P = Record<string, never>, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

export default function GolfMarketplaceApp({ Component, pageProps }: AppPropsWithLayout) {
  useTxWithCache(pageProps.txProps);

  const router = useRouter();
  const rwgToken = router.query.rwg_token;

  const getLayout =
    Component.getLayout || ((page) => <DefaultLayout pageProps={pageProps}>{page}</DefaultLayout>);

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <SessionWrapper>
          <Tooltip.Provider delayDuration={0}>
            <Head>
              <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Head>
            <ErrorBoundary>
              {getLayout(<Component className="z-20" {...pageProps} />)}
            </ErrorBoundary>
          </Tooltip.Provider>
        </SessionWrapper>
      </QueryClientProvider>
      <GoogleTagManager />
      <DataDogInitializer />
      {pageProps.club?.id &&
        rwgToken &&
        typeof rwgToken === 'string' &&
        pageProps.club.features?.integrations?.googleBookOnlineEnabled && (
          <GoogleBookingInitializer clubId={pageProps.club.id} token={rwgToken} />
        )}
      <ZenDeskWidget />
    </>
  );
}
